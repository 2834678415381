<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Agent Withdrawn Applications</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Agent Withdrawn Applications
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-on:keyup.enter="searchAgentApplicationWithdraw"
                      @input="search.info = $event !== null ? $event : ''"
                      label="Lookup by Name, Unique Identifier, Email"
                      v-model="search.info" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-on:keyup.enter="searchAgentApplicationWithdraw"
                      @input="search.mobile = $event !== null ? $event : ''"
                      label="Mobile No."
                      v-model="search.mobile" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      v-on:keyup.enter="searchAgentApplicationWithdraw"
                      dense clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-btn :loading="isLoading"
                         @click="searchAgentApplicationWithdraw"
                         class="mt-1 btn btn-primary"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="text-left">
                  <th class="px-3" :class="sortedClass('first_name')" @click="sortBy('first_name')"><strong>First Name</strong></th>
                  <th><strong>Last Name</strong></th>
                  <th><strong>Unique Identifier</strong></th>
                  <th><strong>Email</strong></th>
                  <th><strong>Phone / Mobile</strong></th>
                  <th><strong>Is Agent</strong></th>
                  <th><strong>Is Student</strong></th>
                  <th><strong>Status</strong></th>
                  <th><strong>Action</strong></th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item, index) in sortedItems" :key="index" >
                    <td>
                      <a class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {{ item.first_name }}
                      </a>
                    </td>
                    <td class="text-left">
                      {{ item.last_name }}
                    </td>
                    <td>
                      {{ item.unique_identifier }}
                    </td>
                    <td>
                      {{ item.email }}
                    </td>
                    <td>
                      {{ item.phone || item.mobile }}
                    </td>
                    <td>
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-success': item.is_agent, 'badge-danger': !item.is_agent }"
                        >{{ item.is_agent ? 'Yes' : 'No' }}</span>
                    </td>
                    <td>
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-success': item.is_student, 'badge-danger': !item.is_student }"
                        >{{ item.is_student ? 'Yes' : 'No' }}</span>
                    </td>
                    <td>
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                        >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>
                    <td>
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">

                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('user', 'delete')">
                              <a class="navi-link" @click.prevent="deleteUser(item.id)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash</v-icon>
                                  </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="sortedItems.length == 0" >
                    <td colspan="9" class="text-center"><strong>No Data Found</strong></td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="sortedItems.length > 0"
                  class="pull-right mt-7"
                  @input="getAllAgentApplicationWithdraws"
                  :disabled="loading"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import AgentApplicationWithdrawService from "@/services/user/agent/application-withdraw/AgentApplicationWithdrawService";

const agentApplicationWithdraw = new AgentApplicationWithdrawService();

export default {
  name: "Index",
  data() {
    return{
      menu: false,
      loading: false,
      isLoading: false,
      users: [],
      total: null,
      perPage: null,
      page: null,
      sort: {
        key: '',
        isAsc: false
      },
      status: [
        { name: 'Active', value: '1'},
        { name: 'Inactive', value: '0'},
      ],
      search:{
        info:'',
        is_active:'',
        type:'',
        mobile: '',
      }
    }
  },
  mounted() {
    this.getAllAgentApplicationWithdraws();
  },
  computed: {
    sortedItems() {
      const list = this.users.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    }
  },
  methods: {
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    getAllAgentApplicationWithdraws(){
      this.loading = true;
      agentApplicationWithdraw
          .paginate(this.search,this.page)
          .then(response => {
            this.users = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    deleteUser(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            agentApplicationWithdraw
                .delete(id)
                .then((response) => {
                  this.getAllAgentApplicationWithdraws()
                  this.$snotify.success("Withdraw application deleted successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    searchAgentApplicationWithdraw(){
      this.getAllAgentApplicationWithdraws();
    },
  }
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>