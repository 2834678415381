import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class AgentApplicationWithdrawService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/agent-application-withdraw';
    }

    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.post(url);
    }

}